<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>参数列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="12"   style="text-align:left;">
                            <div class="searchblock">
                                <el-cascader :options="option" 
                                    v-model="categoryid" 
                                    placeholder="按品类搜索" 
                                    clearable
                                    style="margin-right:10px;" 
                                    :show-all-levels="false"
                                    :props="searchprops"
                                    size="small"
                                    ></el-cascader>
                                <!--<el-select v-model="categoryid" placeholder="按品类搜索" filterable style="margin-right:10px;" clearable>
                                    <el-option
                                        v-for="category in categorys"
                                        :key="category.ID"
                                        :label="category.Category"
                                        :value="category.ID"
                                        >
                                    <span class="sel_cate_name" :class="'depth_' + category.Depth">{{
                                        category.Category
                                    }}</span>
                                    </el-option>
                                </el-select>-->
                                <el-input v-model="keyword" placeholder="参数名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}">
                    <el-table-column label="参数名称" width="200" prop="Param"></el-table-column>
                    <el-table-column label="参数值" min-width="250" prop="ValueSet" >
                        <template #default="scope">
                            <div class="edit-block">
                                <div>{{scope.row.ValueSet}}</div>
                                <div><i class="el-icon-edit-outline" style="color:#0099ff" @click="EditValue(scope.row.ID)"></i></div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属品类" min-width="250" prop="CategorySet" ></el-table-column>
                    <el-table-column label="创建时间" width="150" prop="AddDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="状态" width="100">
                        <template #default="scope">

                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="270">
                        <template #default="scope">
                            
                            <el-button v-if="scope.row.Status == 0" type="text"  @click="Enable(scope.row.ID)"  style="margin-right:20px;">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="text"  @click="Disable(scope.row.ID)"  style="margin-right:20px;">停用</el-button>
                            <el-button type="text"  @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0?false:true"  style="margin-right:20px;">
                                编辑</el-button>
                            <el-button type="text"  @click="Del(scope.row.ID)" :disabled="scope.row.Status == 0?false:true">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="editor" title="编辑参数" width="30%">
        <el-form :model="paraminfo" label-position="left" :rules="rule" ref="paraminfo">
            <el-form-item label="参数名" label-width="100px" prop="paramname">
                <el-input v-model="paraminfo.paramname"></el-input>
            </el-form-item>
            <el-form-item label="所属品类" label-width="100px" prop="category">
                
                <!--<el-select v-model="paraminfo.category" filterable placeholder="请选择" multiple >
                    <el-option
                        v-for="category in categorys"
                        :key="category.ID"
                        :label="category.Category"
                        :value="category.ID"
                        >
                    <span class="sel_cate_name" :class="'depth_' + category.Depth">{{
                        category.Category
                    }}</span>
                    </el-option>
                    
                </el-select>-->
                <el-cascader :options="option" 
                            v-model="paraminfo.category" 
                            placeholder="请选择" 
                            clearable
                            style="margin-right:10px;" 
                            :show-all-levels="false"
                            :props="props"

                            ></el-cascader>
                
            </el-form-item>
            <el-form-item label="参数值" label-width="100px" prop="dynamicTags">
                
                <el-tag
                    :key="tag"
                    v-for="tag in paraminfo.dynamicTags"
                    :closable = disclosable
                    :disable-transitions="false"
                    @close="handleClose(tag)">
                    {{tag}}
                </el-tag>
                
                <el-input class="input-new-val" 
                    v-if="inputVisible" 
                    v-model="inputValue" 
                    ref="saveTagInput" 
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                    size="small">
                </el-input>
                
                <el-button v-else class="button-new-val" @click="showInput" size="small" :disabled="disabled">+ 添加</el-button>
                
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="CloseDialog('paraminfo')">取 消</el-button>
            <el-button type="primary" @click="submitform('paraminfo')">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="valeditor" title="修改参数值" width="30%" @close="Reload()">
        <div v-for="(val,index) in cur_val_arr" :key="val">
            <div class="value-block">
                <el-input style="margin-right:8px;" v-model="val.ParamVal" size="small"></el-input>
                <el-button type="success" @click="SaveVal(val.ID,val.fkParamID,val.ParamVal)" size="small">保存</el-button>
                <el-button type="danger" @click="DelVal(val.ID,val.fkParamID,index)" size="small">删除</el-button>
            </div>
        </div>
        <div style="padding:8px;">
            <el-button type="primary" @click="AddNewValue()" size="small">新增参数值</el-button>
        </div>
    </el-dialog>
</template>
<script>
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            option:[],
            select_id:'',
            valeditor: false,
            cur_val_arr: [],
            keyword:'',
            categoryid:'',
            editor: false,
            paraminfo:{
                paramname:'',
                category:'',
                dynamicTags:[],
                id:''
            },
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled',
                multiple: true,
            },
            searchprops:{
                label:'label',
                value:'value',
                disabled:'Disabled',
                
            },
            disabled: false,
            inputVisible: false,
            disclosable : true,
            inputValue: '',
            rule:{
                paramname:[{
                    required: true, message: '请输入参数名称',trigger: 'blur'
                }],
                category:[{
                    required: true, message: '请选择品类',trigger: 'blur'
                }],
                
                dynamicTags:[{
                    required: true, message: '请填写参数值',trigger: 'blur'
                }]
            },
            tabledata:[]
        }
    },
    methods:{
        add(){
            this.disabled = false;
            this.disclosable = true;
            this.paraminfo.paramname = '';
            this.paraminfo.id ='';
            this.paraminfo.category = '';
            this.paraminfo.dynamicTags = [];
            this.editor = true;
        },
        handleClose(tag) {
            this.paraminfo.dynamicTags.splice(this.paraminfo.dynamicTags.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(()=> {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.paraminfo.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        submitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    console.log(this.paraminfo);
                    if(this.paraminfo.category.length < 0){
                        this.$message.error("请选择品类");
                        return false;
                    }
                    this.axios.post(constant.save_param_url,this.paraminfo,{
                        headers:{
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        const cur_obj = this;
                        if(response.data == "DUPLICATE"){
                            ElMessage.error({
                                type:'error',
                                message:'该参数名已存在'
                            });
                            return false;
                        }else if(response.data == "OK"){
                            cur_obj.editor = false;
                            ElMessage.success({
                                type:'success',
                                message:'操作成功',
                                duration:500,
                                onClose:function(){
                                    cur_obj.$router.go(0);
                                }
                            });
                        }
                    });
                }else{
                    return false;
                }
            })
        },
        CloseDialog(formName){
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        init(){
            this.axios.get(constant.get_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    keyword: this.keyword,
                    categoryid: JSON.stringify(this.categoryid)
                }
            }).then((response)=>{
                console.log(response);
                this.tabledata = response.data;
            });
        },
        Enable(id){
            this.axios.get(constant.enable_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id){
            this.axios.get(constant.disable_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response);
                if(response.data == "OK"){
                    this.$router.go(0);
                }else{
                    ElMessage.error({
                        type:'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Del(id){
            if(!confirm("此操作将删除该参数选项, 是否继续?"))
                return false;
            this.axios.get(constant.del_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                const cur_obj = this;
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'删除成功',
                        duration:1000,
                        onClose:function(){
                            cur_obj.$router.go(0);
                        }
                    });
                }else if(response.data == "ISENABLE"){
                    ElMessage.error({
                        type:'error',
                        message:'删除失败，请停用该参数，且确认没有商品使用该参数再进行删除操作'
                    });
                }
            });
        },
        Edit(id){
            if(!id)
                return false;
            console.log(id);
            this.disclosable = false;
            this.disabled = true;
            this.axios.get(constant.get_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response)
                this.editor = true;
                this.disclosable = false;
                
                this.paraminfo.id = response.data.data.ID;
                this.paraminfo.dynamicTags = response.data.vals;
                this.paraminfo.category = response.data.cates;
                this.paraminfo.paramname = response.data.data.Param;
                //this.modelinfo.status = response.data.Status;
            });
        },
        EditValue(id){
            console.log(id);
            if(id){
                this.select_id = id;
                this.axios.get(constant.get_param_val_url,{
                    headers:{
                       'Content-Type': 'application/json' 
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    this.cur_val_arr = response.data;
                    this.valeditor = true;
                });
            }
           
        },
        AddNewValue(){
            this.cur_val_arr.push({ParamVal:"",ID:'',fkParamID:this.select_id});
        },
        DelVal(id,paramid,index){
            console.log(id);
            console.log(index);
            if(id){
                this.axios.get(constant.del_param_val_url,{
                    headers:{
                       'Content-Type': 'application/json' 
                    },
                    params:{
                        id:id,
                        paramid:paramid
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == 'OK'){
                        ElMessage.success({
                            type:'success',
                            message:'删除成功'
                        });
                        if (index !== -1){
                            this.cur_val_arr.splice(index,1);
                        }
                    }else if(response.data == 'USING'){
                        ElMessage.error({
                            type:'error',
                            message:'有正在使用该参数值的套餐，无法进行删除操作'
                        });
                    }else{
                        ElMessage.error({
                            type:'error',
                            message:'保存失败'
                        });
                    }
                });
            }
            
            
        },
        SaveVal(id,paramid,paramval){
            console.log(id);
            console.log(paramid);
            console.log(paramval);
            
            if(id){
                this.axios.get(constant.save_param_val_url,{
                    headers:{
                       'Content-Type': 'application/json' 
                    },
                    params:{
                        id:id,
                        paramid:paramid,
                        paramval:paramval
                    }
                }).then((response)=>{
                    if(response.data == "OK"){
                        //this.valeditor = false;
                        ElMessage.success({
                            type:'success',
                            message:'保存成功'
                        });
                    }else if(response.data == "USING"){
                        ElMessage.error({
                            type:'error',
                            message:'有正在使用该参数值的商品，无法进行修改操作'
                        });
                    }else{
                        ElMessage.error({
                            type:'error',
                            message:'保存失败'
                        });
                    }
                });
            }else{
                this.axios.get(constant.save_param_val_url,{
                    headers:{
                       'Content-Type': 'application/json' 
                    },
                    params:{
                        paramid:paramid,
                        paramval:paramval
                    }
                }).then((response)=>{
                    if(response.data == "OK"){
                        //this.valeditor = false;
                        ElMessage.success({
                            type:'success',
                            message:'保存成功'
                        });
                    }else{
                        ElMessage.error({
                            type:'error',
                            message:'保存失败'
                        });
                    }
                });
            }
        },
        Reload(){
            this.$router.go(0);
        }
    },
    created:function(){
        
        this.axios.get(constant.get_param_url,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            console.log(response.data);
            this.tabledata = response.data;
        });
        /*
        this.axios.get(constant.get_tree_url,{
            headers: {
                "Content-Type": "application/json",
            },
            params:{
                status:1
            }
        }).then((response)=>{
            console.log(response.data);
            this.categorys = response.data;
        });*/
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });
    }
    
}
</script>
<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-val {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-val {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.edit-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.value-block {
    padding:8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
</style>